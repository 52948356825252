import React, { useCallback, useState } from "react";
import styles from "./account-details.module.scss";
import {
  prettifyAccountStatus,
  formatVolume,
  formatCurrency,
  formatNumber,
  formatPesoWithDecimalRange,
  isEmailValid,
  prettifyFleetAccountType,
} from "utils";
import { AccountStatus, FleetAccountType, PLBTypes } from "enums";
import locale from "localization";
import {
  Field,
  TextField,
  Title,
  Dropdown,
  Autocomplete,
  Button,
  Pill,
  Text,
  DatePicker,
  Image,
} from "components/commons";
import {
  SelectStation,
  InputAmount,
  InputLitre,
  InputChip,
  InputSwitch,
  SelectRedemptionStation,
} from "components/field";
import classNames from "classnames";
import PlatformType from "enums/platform-type";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import {
  prettifyIntroductoryPromoOffer,
  prettifyPlbType,
  prettifyInvoicingSetUp,
} from "utils/pretty.utils";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { getStations } from "apis";
import { useApi, useModal, usePagination, useFilter } from "hooks";
import { TargetStationsFilterState } from "./target-stations-filter.state";
import { RemoveIcon } from "images";
import ImportStationModal from "./import-station";
import TargetStationsModal from "./target-stations-modal";
import ImportFleetCardModal from "./import-fleet-card";
import CloseIcon from "@material-ui/icons/Close";
import SelectedFleetCardModal from "./selected-fleet-card-modal";

const AccountDetailsFormModule = ({ stationPricings = [], ...stateForm }) => {
  const {
    switchStatus,
    viewMode,
    addMode,
    editMode,
    otherDetails,
    fields,
    isFormSubmittable,
    modifyField,
    loading,
    populateProvince,
    pronvinceData,
    clearProvince,
    cities,
    provinceKeyFilter,
    submitForm,
    handleSubmit,
    onChangeTypeOfBusinessCb,
    onChangeAccountTypeCb,
    handleAddEmail,
    onDeleteEmailAdrresses,
    handleStatusChange,
    selectRedemptionStationCb,
    onChangeShortNameCb,
    onChangeTelephoneCb,
    inputRef,
    validatedField,
    modifyForm,
    computeAutoPopulatedData,
    loadingStationPricing,
    computeContractPrice,
    getPriceMovement,
    setDiscountCombination,
    discountCombination,
    stationsSelected,
    setStationsSelected,
    addRow,
    onRemove,
    onChangeDieselPrice,
    onChangeGas91Price,
    onChangeGas95Price,
    onChangeGas97Price,
    onChangePLBTypeCb,
    fleetCardSelected,
    setFleetCardSelected,
  } = stateForm;

  if (editMode) {
    if (!fields.existingGas91Volume.value) fields.existingGas91Volume.value = formatNumber(0, 3);
    if (!fields.existingGas95Volume.value) fields.existingGas95Volume.value = formatNumber(0, 3);
    if (!fields.existingGas97Volume.value) fields.existingGas97Volume.value = formatNumber(0, 3);
    if (!fields.existingDieselVolume.value) fields.existingDieselVolume.value = formatNumber(0, 3);
  }
  const importStationModal = useModal();
  const importFleetCardModal = useModal();
  const stationsModal = useModal();
  const selectedFleetCardModal = useModal();
  const [isAllStation, setIsAllStation] = useState({ checked: false });
  const [inputStationCount, setInputStationCount] = useState(0);
  const [stationslist, setStationsList] = useState([]);
  const [stationInputs, setStationInputs] = useState([]);

  const {
    filterState,
    requestState,
    submitFilter,
    modifyFilters,
    filterCount,
    modifyFilter,
    clearFilter,
    submittedFilter,
  } = useFilter(TargetStationsFilterState());

  const { request: getStationsRequest, loading: loadingStations } = useApi({
    api: getStations,
    modalError: true,
    params: {
      platformType: "plb",
    },
  });

  const stationData = usePagination();
  const fetchStations = useCallback(
    async (rs = { ...requestState }, perPage) => {
      if (rs.reset) {
        stationData.reset();
      }
      if (stationData.canLoadMore || rs.reset) {
        const result = await getStationsRequest(
          submitFilter({
            perPage: perPage ? perPage : 10,
            ...rs,
            page: rs.reset ? 1 : stationData.page,
          }),
          null
        );
        stationData.onLoadMore({ data: result.stations, total: result.count, resetPage: rs.reset });

        // saves the count for the first render only
        if (inputStationCount === 0) {
          setInputStationCount(result?.count);
          setStationsList(result?.stations);
        }
        return result;
      }
    },
    [getStationsRequest, requestState, inputStationCount, submitFilter, stationData]
  );

  const selectStationsCb = useCallback(
    (stationParams) => {
      let newStations = [];
      let isAllSelected = false;

      const stationSelected = stationInputs.some((station) => {
        return station.value === stationParams.value;
      });
      if (stationSelected) {
        newStations = stationInputs.filter((station) => station.value !== stationParams.value);
        setStationsSelected([
          ...stationsSelected.filter((station) => station.value !== stationParams.value),
        ]);
      } else {
        const clone = [...stationInputs];
        newStations = [...clone, stationParams];
      }

      if (inputStationCount === newStations.length) {
        isAllSelected = true;
      }

      setIsAllStation({ checked: isAllSelected });
      setStationInputs(newStations);
    },
    [inputStationCount, stationInputs, setStationsSelected, stationsSelected]
  );

  const onClearSelectedStation = useCallback(
    (index) => {
      const newForm = [...discountCombination];
      newForm[index]["stationIds"].value = [];
      setDiscountCombination(newForm);
      setStationInputs([]);
      setStationsSelected([]);
    },
    [discountCombination, setDiscountCombination, setStationsSelected]
  );

  const saveStationsFromCSVCb = useCallback(
    (index, selectedStations) => {
      const newForm = [...discountCombination];
      newForm[index]["stationIds"].value = selectedStations;
      setDiscountCombination(newForm);
      setStationInputs(selectedStations);
    },
    [setDiscountCombination, discountCombination]
  );

  const saveFleetCardsFromCSVCb = useCallback(
    (selectedFleetCards) => {
      setFleetCardSelected(selectedFleetCards);
      modifyField(fields.fuelCards.name, { value: selectedFleetCards });
    },
    [fields.fuelCards.name, modifyField, setFleetCardSelected]
  );

  const saveStationsCb = useCallback(
    (index) => {
      const newForm = [...discountCombination];
      newForm[index]["stationIds"].value = stationInputs;
      setDiscountCombination(newForm);
      let selectedStations = [];
      stationInputs.forEach((station) => {
        station.id = index;
        const existing = stationsSelected.some((selected) => {
          return selected.value === station.value;
        });
        if (!existing) {
          selectedStations.push(station);
        }
      });
      setStationsSelected([...stationsSelected, ...selectedStations]);
    },
    [
      setDiscountCombination,
      setStationsSelected,
      stationInputs,
      discountCombination,
      stationsSelected,
    ]
  );

  const validateDiscount = useCallback(() => {
    let hasError = false;
    if (fields.plbType.value !== PLBTypes.PLB_HEDGING) {
      const data = [...discountCombination];

      var count = 0;
      data.forEach((item) => {
        if (
          item.stationIds.value.length === 0 ||
          item.discountDiesel.value === "" ||
          item.discountGas91.value === "" ||
          item.discountGas95.value === "" ||
          item.discountGas97.value === ""
        ) {
          hasError = true;
        }

        if (
          item.stationIds.value.length >= 0 &&
          item.discountDiesel.value === "0.00" &&
          item.discountGas91.value === "0.00" &&
          item.discountGas95.value === "0.00" &&
          item.discountGas97.value === "0.00"
        ) {
          hasError = true;
        }

        if (data.length === 1 && count === 0) {
          if (
            item.stationIds.value.length === 0 &&
            (item.discountDiesel.value === "" || item.discountDiesel.value === "0.00") &&
            (item.discountGas91.value === "" || item.discountGas91.value === "0.00") &&
            (item.discountGas95.value === "" || item.discountGas95.value === "0.00") &&
            (item.discountGas97.value === "" || item.discountGas97.value === "0.00")
          ) {
            hasError = false;
          }
        }
        count++;
      });
      return hasError;
    } else {
      return false;
    }
  }, [fields.plbType, discountCombination]);

  const targetStationsProps = {
    filterState,
    modifyFilters,
    filterCount,
    modifyFilter,
    clearFilter,
    selectStationsCb,
    stationInputs,
    setStationInputs,
    saveStationsCb,
    fetchStations,
    loadingStations,
    isAllStation,
    setIsAllStation,
    inputStationCount,
    stationslist,
    setStationsList,
    submittedFilter,
    stationData,
    onClearSelectedStation,
    stationsSelected,
    setStationsSelected,
  };

  const selectedFleetCardProps = {
    fleetCardSelected,
  };

  return (
    <>
      <TargetStationsModal {...stationsModal} {...targetStationsProps} />
      <SelectedFleetCardModal {...selectedFleetCardModal} {...selectedFleetCardProps} />
      <ImportStationModal
        {...importStationModal}
        request={""}
        stationData={stationData}
        setStationInputs={setStationInputs}
        saveStationsCb={saveStationsFromCSVCb}
        stationInputs={stationInputs}
        discountCombination={discountCombination}
        setStationsSelected={setStationsSelected}
        stationsSelected={stationsSelected}
        isHedging={fields.plbType === PLBTypes.PLB_HEDGING}
      />
      <ImportFleetCardModal
        {...importFleetCardModal}
        saveFleetCardsCb={saveFleetCardsFromCSVCb}
        stationInputs={stationInputs}
        setFleetCardSelected={setFleetCardSelected}
        fleetCardSelected={fleetCardSelected}
      />

      <div className={styles.container}>
        <Title xsmall>{locale.businessInformation}</Title>
        {viewMode && (
          <Field className={styles.fieldLabel} label={locale.status} horizontal>
            <Pill
              grass={otherDetails.status === AccountStatus.Active}
              cheddar={otherDetails.status === AccountStatus.Pending}
              deepRed={otherDetails.status === AccountStatus.Deactivated}
              cement={otherDetails.status === AccountStatus.Expired}
            >
              {prettifyAccountStatus(otherDetails.status)}
            </Pill>
          </Field>
        )}
        {editMode && (
          <Field className={styles.fieldLabel} label={locale.status} horizontal>
            <InputSwitch
              label={prettifyAccountStatus(fields.status.value)}
              checked={switchStatus}
              onChange={handleStatusChange}
              disabled={
                fields.status.value === AccountStatus.Pending ||
                fields.status.value === AccountStatus.Expired ||
                loading
              }
            />
          </Field>
        )}
        <Field className={styles.fieldLabel} {...fields.businessName}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields.businessName.value}</Text>
          ) : (
            <TextField disabled={loading} {...fields.businessName} onChange={modifyField} />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.businessNature}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields.businessNature.value}</Text>
          ) : (
            <TextField disabled={loading} {...fields.businessNature} onChange={modifyField} />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.businessType}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields.businessType.value}</Text>
          ) : (
            <Dropdown
              disabled={loading}
              onChange={onChangeTypeOfBusinessCb}
              {...fields.businessType}
            />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.businessAddress}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields.businessAddress.value}</Text>
          ) : (
            <TextField disabled={loading} {...fields.businessAddress} onChange={modifyField} />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.province}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields.province.value}</Text>
          ) : (
            <Autocomplete
              disabled={loading}
              options={pronvinceData}
              onChange={populateProvince}
              {...fields.province}
              onInputChange={clearProvince}
            />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.city}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields.city.value}</Text>
          ) : (
            <Autocomplete
              onChange={modifyField}
              options={cities}
              disabled={!provinceKeyFilter || loading}
              {...fields.city}
            />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.emailAddresses}>
          {viewMode ? (
            <div className={styles.pillEmailAddressesContainer}>
              {fields.emailAddresses.value.map((e, i) => (
                <Pill desertstorm className={styles.emailAddressInPill} key={i}>
                  <span>{e.emailAddress}</span>
                  <br />
                  <span
                    className={classNames({
                      [styles.active]: e.status === AccountStatus.Active,
                      [styles.pending]: e.status === AccountStatus.Pending,
                      [styles.expired]: e.status === AccountStatus.Expired,
                      [styles.deactivated]: e.status === AccountStatus.Deactivated,
                    })}
                  >
                    {prettifyAccountStatus(e.status)}
                  </span>
                </Pill>
              ))}
            </div>
          ) : (
            <InputChip
              disabled={loading || switchStatus}
              {...fields.emailAddresses}
              handlePushValue={handleAddEmail}
              maxIndexValue={5}
              onDelete={onDeleteEmailAdrresses}
              className={styles.emailAddresses}
              validator={(text) => {
                const isError = text ? !isEmailValid(text) : false;

                return {
                  error: isError,
                  message: isError ? locale.pleaseEnterAValidEmailAddress : "",
                };
              }}
            />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.shortName}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields.shortName.value}</Text>
          ) : (
            <TextField
              ref={inputRef}
              disabled={loading}
              {...fields.shortName}
              onChange={onChangeShortNameCb}
            />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.acquirerName}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields.acquirerName.value}</Text>
          ) : (
            <TextField disabled={loading} {...fields.acquirerName} onChange={modifyField} />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.acquirerEmail}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields.acquirerEmail.value}</Text>
          ) : (
            <TextField disabled={loading} {...fields.acquirerEmail} onChange={modifyField} />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.plbType}>
          {addMode ? (
            <Autocomplete {...fields.plbType} disabled={loading} onChange={onChangePLBTypeCb} />
          ) : (
            <Text className={styles.fieldText}>{prettifyPlbType(fields.plbType.value)}</Text>
          )}
        </Field>

        <Field className={styles.fieldLabel} {...fields.invoicingSetup}>
          {viewMode && (
            <Text className={styles.fieldText}>
              {prettifyInvoicingSetUp(fields.invoicingSetup.value)}
            </Text>
          )}

          {(addMode || editMode) && (
            <Autocomplete {...fields.invoicingSetup} disabled={loading} onChange={modifyField} />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.introductoryPromoOffer}>
          {addMode ? (
            <Autocomplete
              {...fields.introductoryPromoOffer}
              disabled={loading}
              onChange={modifyField}
            />
          ) : (
            <Text className={styles.fieldText}>
              {prettifyIntroductoryPromoOffer(fields.introductoryPromoOffer.value)}
            </Text>
          )}
        </Field>
        <Title xsmall>{locale.contactInformation}</Title>
        <Field className={styles.fieldLabel} {...fields.businessTelephoneNumber}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields.businessTelephoneNumber.value}</Text>
          ) : (
            <TextField
              disabled={loading}
              {...fields.businessTelephoneNumber}
              onChange={onChangeTelephoneCb}
            />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.businessRepresentativeName}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields.businessRepresentativeName.value}</Text>
          ) : (
            <TextField
              disabled={loading}
              {...fields.businessRepresentativeName}
              onChange={modifyField}
            />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.representativeMobileNumber}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields.representativeMobileNumber.value}</Text>
          ) : (
            <TextField
              disabled={loading}
              {...fields.representativeMobileNumber}
              onBlur={validatedField}
              onChange={modifyField}
            />
          )}
        </Field>
        <Field className={styles.fieldLabel} {...fields.representativeEmail}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields.representativeEmail.value}</Text>
          ) : (
            <TextField disabled={loading} {...fields.representativeEmail} onChange={modifyField} />
          )}
        </Field>

        {!(editMode || viewMode) && (
          <>
            {(fields?.plbType.value === PLBTypes.PLB_LOCQPAY ||
              fields?.plbType.value === PLBTypes.PLB_LOCQPAY_MANUAL_PO) && (
              <>
                <Title style={{ marginBottom: "0px", marginTop: "40px" }} xsmall>
                  {locale.fleetCard}
                </Title>
                <Field className={styles.fieldLabel} label="Upload Fleet Card" horizontal>
                  <div className={styles.buttonHorizontal}>
                    {fleetCardSelected.length > 0 && (
                      <Button
                        outline
                        onClick={() => {
                          // fetchStations({ reset: true, perPage: 10 });
                          selectedFleetCardModal.show();
                        }}
                      >
                        {locale.viewCards}
                      </Button>
                    )}
                    <Button
                      startIcon={<GetAppRoundedIcon />}
                      outline
                      onClick={() => {
                        importFleetCardModal.show({});
                      }}
                    >
                      {locale.importCSV}
                    </Button>
                    {fleetCardSelected.length > 0 && (
                      <div className={styles.stationCount}>
                        <span className={styles.numberStationSelected}>
                          {
                            <locale.Populate
                              text={"{0} Fleet Cards"}
                              items={[fleetCardSelected.length]}
                            />
                          }
                        </span>
                        <CloseIcon
                          className={styles.clearIcon}
                          onClick={() => {
                            setFleetCardSelected([]);
                            modifyField(fields.fuelCards.name, { value: null });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Field>
              </>
            )}
            <Title xsmall>
              {fields?.plbType.value === PLBTypes.PLB_LOCQPAY ||
              fields?.plbType.value === PLBTypes.PLB_LOCQPAY_MANUAL_PO
                ? locale.contractDiscounts
                : locale.onboardingPricingInformation}
            </Title>
            {fields?.plbType.value === PLBTypes.PLB_HEDGING && (
              <Field
                className={styles.fieldLabel}
                {...fields.referenceStationId}
                label={fields.referenceStationId.inputLabel}
              >
                {addMode ? (
                  <SelectStation
                    {...fields.referenceStationId}
                    onChange={computeAutoPopulatedData}
                    disabled={loading}
                    isPlbOnboarded={true}
                    isOnboarding={true}
                    platformType="plb"
                  />
                ) : (
                  <Text className={styles.fieldText}>{fields.referenceStationId.label}</Text>
                )}
              </Field>
            )}

            <Field
              className={styles.fieldLabel}
              {...fields.accountType}
              label={fields.accountType.label}
            >
              {addMode ? (
                <Autocomplete
                  {...fields.accountType}
                  disabled={loading}
                  onChange={onChangeAccountTypeCb}
                />
              ) : (
                <Text>{prettifyFleetAccountType(fields.accountType.value)}</Text>
              )}
            </Field>
            {fields?.plbType.value === PLBTypes.PLB_HEDGING && (
              <>
                <Field
                  className={styles.fieldLabel}
                  {...fields.fleetStations}
                  label={fields.fleetStations.inputLabel}
                >
                  {viewMode ? (
                    <Text className={styles.fieldText}>
                      {!fields.fleetStations.value.length ? (
                        <p className={styles.italic}>{locale.allStations}</p>
                      ) : (
                        fields.fleetStations.value
                          .map((value) => {
                            return value.label;
                          })
                          .sort()
                          .join(", ")
                      )}
                    </Text>
                  ) : (
                    <SelectRedemptionStation
                      {...fields.fleetStations}
                      onChange={selectRedemptionStationCb}
                      disabled={loading || fields.accountType.value === FleetAccountType.KeyAccount}
                      params={{
                        platformType: PlatformType.PLB,
                      }}
                    />
                  )}
                </Field>
                {addMode && (
                  <>
                    <Field
                      className={styles.fieldLabel}
                      {...fields.priceAssessmentDate}
                      label={fields.priceAssessmentDate.label}
                    >
                      <DatePicker
                        {...fields.priceAssessmentDate}
                        disableEndDate={moment()}
                        onChange={computeAutoPopulatedData}
                      />
                    </Field>
                  </>
                )}
              </>
            )}
            {fields.priceAssessmentDate.value && (editMode || viewMode) && (
              <Field
                className={styles.fieldLabel}
                {...fields.depotId}
                label={fields.priceAssessmentDate.label}
              >
                <Text className={styles.fieldText}>{fields.priceAssessmentDate.value || ""}</Text>
              </Field>
            )}
            {(editMode || viewMode) && (
              <Field className={styles.fieldLabel} {...fields.depotId} label={"Date Onboarded"}>
                <Text className={styles.fieldText}>{fields.dateTimeOnboarded.value || ""}</Text>
              </Field>
            )}
            {fields?.plbType.value === PLBTypes.PLB_HEDGING && (
              <>
                <Field
                  className={styles.fieldLabel}
                  {...fields.referenceStationType}
                  label={fields.referenceStationType.label}
                >
                  <Text className={styles.fieldText}>
                    {fields.priceAssessmentDate.value
                      ? stationPricings[0]?.stationType || ""
                      : otherDetails.referenceStationType || ""}
                  </Text>
                </Field>
                <Field className={styles.fieldLabel} {...fields.depotId} label={"Depot"}>
                  <Text className={styles.fieldText}>
                    {fields.priceAssessmentDate.value
                      ? stationPricings[0]?.depot.name || ""
                      : otherDetails.depot || ""}
                  </Text>
                </Field>
              </>
            )}
            {(fields?.plbType.value === PLBTypes.PLB_LOCQPAY ||
              fields?.plbType.value === PLBTypes.PLB_LOCQPAY_MANUAL_PO) && (
              <>
                <Title style={{ marginBottom: "0px", marginTop: "40px" }} xsmall>
                  {locale.station}
                </Title>
                {discountCombination.map((item, i) => (
                  <div className={styles.stationReference}>
                    {item?.stationIds?.value?.length === 0 ? (
                      <>
                        <Button
                          startIcon={<span className="icon-pencil" />}
                          outline
                          onClick={() => {
                            stationsModal.show({
                              index: i,
                            });
                            fetchStations({ reset: true, perPage: 10 });
                          }}
                        >
                          {locale.selectStations}
                        </Button>
                        <Button
                          startIcon={<GetAppRoundedIcon />}
                          outline
                          onClick={() => {
                            importStationModal.show({
                              index: i,
                            });
                            fetchStations({ reset: true, perPage: 1000 });
                          }}
                        >
                          {locale.importCSV}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          startIcon={<span className="icon-pencil" />}
                          outline
                          onClick={() => {
                            stationsModal.show({
                              index: i,
                            });
                            setStationInputs(item?.stationIds?.value);
                            fetchStations({ reset: true, perPage: 10 });
                          }}
                        >
                          {locale.editStations}
                        </Button>
                        <Button
                          startIcon={<GetAppRoundedIcon />}
                          outline
                          onClick={() => {
                            importStationModal.show({
                              index: i,
                            });
                            setStationInputs(item.stationIds?.value);
                            fetchStations({ reset: true, perPage: 1000 });
                          }}
                        >
                          {locale.importCSV}
                        </Button>
                      </>
                    )}

                    <div className={styles.stationCount}>
                      <span className={styles.numberStationSelected}>
                        {
                          <locale.Populate
                            text={
                              [item?.stationIds?.value?.length] <= 1
                                ? "{0} Station"
                                : locale.stationsEligibleSelected
                            }
                            items={[item?.stationIds?.value?.length]}
                          />
                        }
                      </span>
                    </div>

                    {addMode || editMode ? (
                      <Field
                        className={classNames(styles.fieldLabelGas, styles.fieldPricingGasLabel)}
                        label={locale.diesel}
                        vertical
                      >
                        <InputAmount
                          {...item.discountDiesel}
                          onChange={(_, { value }) => {
                            onChangeDieselPrice(value, i);
                          }}
                          className={styles.gasAmount}
                          disabled={loading}
                        />
                      </Field>
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(item.discountDiesel.value)}
                      </Text>
                    )}

                    {addMode || editMode ? (
                      <Field
                        className={classNames(styles.fieldLabelGas, styles.fieldPricingGasLabel)}
                        label={locale.gas91}
                        vertical
                      >
                        <InputAmount
                          {...item.discountGas91}
                          onChange={(_, { value }) => {
                            onChangeGas91Price(value, i);
                          }}
                          className={styles.gasAmount}
                          disabled={loading}
                        />
                      </Field>
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(item.discountGas91.value)}
                      </Text>
                    )}

                    {addMode || editMode ? (
                      <Field
                        className={classNames(styles.fieldLabelGas, styles.fieldPricingGasLabel)}
                        label={locale.gas95}
                        vertical
                      >
                        <InputAmount
                          {...item.discountGas95}
                          onChange={(_, { value }) => {
                            onChangeGas95Price(value, i);
                          }}
                          className={styles.gasAmount}
                          disabled={loading}
                        />
                      </Field>
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(item.discountGas95.value)}
                      </Text>
                    )}

                    {addMode || editMode ? (
                      <Field
                        className={classNames(styles.fieldLabelGas, styles.fieldPricingGasLabel)}
                        label={locale.gas97}
                        vertical
                      >
                        <InputAmount
                          {...item.discountGas97}
                          onChange={(_, { value }) => {
                            onChangeGas97Price(value, i);
                          }}
                          className={styles.gasAmount}
                          disabled={loading}
                        />
                      </Field>
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(item.discountGas97.value)}
                      </Text>
                    )}
                    {i === 0 ? (
                      <span className={styles.noRemoveCohort}></span>
                    ) : (
                      <span
                        onClick={() => {
                          onClearSelectedStation(i);
                          onRemove(i);
                        }}
                        className={styles.removeCohort}
                      >
                        <Image src={RemoveIcon} />
                      </span>
                    )}
                  </div>
                ))}
                <Button outline className={styles.continue} onClick={addRow}>
                  {locale.addNewDiscountCombination}
                </Button>
              </>
            )}
            <Field
              className={classNames(
                styles.fieldLabel,
                fields?.plbType.value === PLBTypes.PLB_HEDGING
                  ? styles.fieldPricingLabel
                  : styles.fieldPricingLabelLOCQPay,
                styles.fieldPricingLabelPosition
              )}
              horizontal
            >
              <div className={classNames(styles.horizontalInput)}>
                {fields?.plbType.value === PLBTypes.PLB_HEDGING && (
                  <>
                    <Title xsmall className={styles.gasTitle}>
                      {locale.refsPumpPriceAtAssessment}
                    </Title>
                    <Title xsmall className={styles.gasTitle}>
                      {locale.agreedDiscounts}
                    </Title>
                    <Title xsmall className={styles.gasTitle}>
                      {locale.accuMovementSinceAssessment}
                    </Title>
                    <Title xsmall className={styles.gasTitle}>
                      {locale.contractPriceAtOnboarding}
                    </Title>
                    <Title xsmall className={styles.gasTitle}>
                      {locale.sdum}
                    </Title>
                    <Title xsmall className={styles.gasTitle}>
                      {locale.freight}
                    </Title>
                  </>
                )}

                <Title xsmall className={styles.gasTitle}>
                  {locale.estimateMonthlyVolume}
                </Title>
                <Title xsmall className={styles.gasTitle}>
                  {locale.existingVolume}
                </Title>
              </div>
            </Field>
            <Field
              className={classNames(
                fields?.plbType.value === PLBTypes.PLB_HEDGING
                  ? styles.fieldLabel
                  : styles.fieldLabelLOCQPay,
                fields?.plbType.value === PLBTypes.PLB_HEDGING
                  ? styles.fieldPricingLabel
                  : styles.fieldPricingLabelLOCQPay
              )}
              label={locale.diesel}
              horizontal
            >
              <div className={styles.horizontalInput}>
                {fields?.plbType.value === PLBTypes.PLB_HEDGING && (
                  <>
                    {loadingStationPricing ? (
                      <Skeleton style={{ width: "100%" }} />
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(fields.baseDieselPrice.value)}
                      </Text>
                    )}
                    {addMode || editMode ? (
                      <InputAmount
                        {...fields.discountDiesel}
                        onChange={(name, { value }) => {
                          if (editMode) {
                            modifyForm({
                              [name]: { value },
                            });
                          } else {
                            modifyForm({
                              [name]: { value },
                              contractPriceDiesel: {
                                value: computeContractPrice(
                                  fields.baseDieselPrice.value,
                                  fields.accuMovementDiesel.value,
                                  value
                                ),
                              },
                            });
                          }
                        }}
                        className={styles.gasAmount}
                        disabled={loading}
                      />
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(fields.discountDiesel.value)}
                      </Text>
                    )}
                    {getPriceMovement?.loading ? (
                      <Skeleton style={{ width: "100%" }} />
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(fields.accuMovementDiesel.value)}
                      </Text>
                    )}
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatCurrency(fields.contractPriceDiesel.value)}
                    </Text>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatCurrency(fields.sdumDiesel.value)}
                    </Text>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatPesoWithDecimalRange(fields.freightDiesel.value, 4)}
                    </Text>
                  </>
                )}
                {addMode || editMode ? (
                  <>
                    <InputLitre
                      {...fields.estimatedDieselVolume}
                      onChange={modifyField}
                      className={styles.gasAmount}
                      disabled={loading}
                    />
                    <InputLitre
                      {...fields.existingDieselVolume}
                      // value={formatNumber(fields.existingDieselVolume.value, 3)}
                      onChange={modifyField}
                      className={styles.gasAmount}
                      disabled={loading}
                    />
                  </>
                ) : (
                  <>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatVolume(fields.estimatedDieselVolume.value)}
                    </Text>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatVolume(fields.existingDieselVolume.value)}
                    </Text>
                  </>
                )}
              </div>
            </Field>
            <Field
              className={classNames(
                fields?.plbType.value === PLBTypes.PLB_HEDGING
                  ? styles.fieldLabel
                  : styles.fieldLabelLOCQPay,
                fields?.plbType.value === PLBTypes.PLB_HEDGING
                  ? styles.fieldPricingLabel
                  : styles.fieldPricingLabelLOCQPay
              )}
              label={locale.gas91}
              horizontal
            >
              <div className={styles.horizontalInput}>
                {fields?.plbType.value === PLBTypes.PLB_HEDGING && (
                  <>
                    {loadingStationPricing ? (
                      <Skeleton style={{ width: "100%" }} />
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(fields.baseGas91Price.value)}
                      </Text>
                    )}
                    {addMode || editMode ? (
                      <InputAmount
                        {...fields.discountGas91}
                        onChange={(name, { value }) => {
                          if (editMode) {
                            modifyForm({
                              [name]: { value },
                            });
                          } else {
                            modifyForm({
                              [name]: { value },
                              contractPriceGas91: {
                                value: computeContractPrice(
                                  fields.baseGas91Price.value,
                                  fields.accuMovementGas91.value,
                                  value
                                ),
                              },
                            });
                          }
                        }}
                        className={styles.gasAmount}
                        disabled={loading}
                      />
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(fields.discountGas91.value)}
                      </Text>
                    )}
                    {getPriceMovement?.loading ? (
                      <Skeleton style={{ width: "100%" }} />
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(fields.accuMovementGas91.value)}
                      </Text>
                    )}
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatCurrency(fields.contractPriceGas91.value)}
                    </Text>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatCurrency(fields.sdumGas91.value)}
                    </Text>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatPesoWithDecimalRange(fields.freightGas91.value, 4)}
                    </Text>
                  </>
                )}
                {addMode || editMode ? (
                  <>
                    <InputLitre
                      {...fields.estimatedGas91Volume}
                      onChange={modifyField}
                      className={styles.gasAmount}
                      disabled={loading}
                    />
                    <InputLitre
                      {...fields.existingGas91Volume}
                      // value={formatNumber(fields.existingGas91Volume.value, 3)}
                      onChange={modifyField}
                      className={styles.gasAmount}
                      disabled={loading}
                    />
                  </>
                ) : (
                  <>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatVolume(fields.estimatedGas91Volume.value)}
                    </Text>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatVolume(fields.existingGas91Volume.value)}
                    </Text>
                  </>
                )}
              </div>
            </Field>
            <Field
              className={classNames(
                fields?.plbType.value === PLBTypes.PLB_HEDGING
                  ? styles.fieldLabel
                  : styles.fieldLabelLOCQPay,
                fields?.plbType.value === PLBTypes.PLB_HEDGING
                  ? styles.fieldPricingLabel
                  : styles.fieldPricingLabelLOCQPay
              )}
              label={locale.gas95}
              horizontal
            >
              <div className={styles.horizontalInput}>
                {fields?.plbType.value === PLBTypes.PLB_HEDGING && (
                  <>
                    {loadingStationPricing ? (
                      <Skeleton style={{ width: "100%" }} />
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(fields.baseGas95Price.value)}
                      </Text>
                    )}
                    {addMode || editMode ? (
                      <InputAmount
                        {...fields.discountGas95}
                        onChange={(name, { value }) => {
                          if (editMode) {
                            modifyForm({
                              [name]: { value },
                            });
                          } else {
                            modifyForm({
                              [name]: { value },
                              contractPriceGas95: {
                                value: computeContractPrice(
                                  fields.baseGas95Price.value,
                                  fields.accuMovementGas95.value,
                                  value
                                ),
                              },
                            });
                          }
                        }}
                        className={styles.gasAmount}
                        disabled={loading}
                      />
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(fields.discountGas95.value)}
                      </Text>
                    )}
                    {getPriceMovement?.loading ? (
                      <Skeleton style={{ width: "100%" }} />
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(fields.accuMovementGas95.value)}
                      </Text>
                    )}
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatCurrency(fields.contractPriceGas95.value)}
                    </Text>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatCurrency(fields.sdumGas95.value)}
                    </Text>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatPesoWithDecimalRange(fields.freightGas95.value, 4)}
                    </Text>
                  </>
                )}
                {addMode || editMode ? (
                  <>
                    <InputLitre
                      {...fields.estimatedGas95Volume}
                      onChange={modifyField}
                      className={styles.gasAmount}
                      disabled={loading}
                    />
                    <InputLitre
                      {...fields.existingGas95Volume}
                      // value={formatNumber(fields.existingGas95Volume.value, 3)}
                      onChange={modifyField}
                      className={styles.gasAmount}
                      disabled={loading}
                    />
                  </>
                ) : (
                  <>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatVolume(fields.estimatedGas95Volume.value)}
                    </Text>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatVolume(fields.existingGas95Volume.value)}
                    </Text>
                  </>
                )}
              </div>
            </Field>
            <Field
              className={classNames(
                fields?.plbType.value === PLBTypes.PLB_HEDGING
                  ? styles.fieldLabel
                  : styles.fieldLabelLOCQPay,
                fields?.plbType.value === PLBTypes.PLB_HEDGING
                  ? styles.fieldPricingLabel
                  : styles.fieldPricingLabelLOCQPay
              )}
              label={locale.gas97}
              horizontal
            >
              <div className={styles.horizontalInput}>
                {fields?.plbType.value === PLBTypes.PLB_HEDGING && (
                  <>
                    {loadingStationPricing ? (
                      <Skeleton style={{ width: "100%" }} />
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(fields.baseGas97Price.value)}
                      </Text>
                    )}
                    {addMode || editMode ? (
                      <InputAmount
                        {...fields.discountGas97}
                        onChange={(name, { value }) => {
                          if (editMode) {
                            modifyForm({
                              [name]: { value },
                            });
                          } else {
                            modifyForm({
                              [name]: { value },
                              contractPriceGas97: {
                                value: computeContractPrice(
                                  fields.baseGas97Price.value,
                                  fields.accuMovementGas97.value,
                                  value
                                ),
                              },
                            });
                          }
                        }}
                        className={styles.gasAmount}
                        disabled={loading}
                      />
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(fields.discountGas97.value)}
                      </Text>
                    )}
                    {getPriceMovement?.loading ? (
                      <Skeleton style={{ width: "100%" }} />
                    ) : (
                      <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                        {formatCurrency(fields.accuMovementGas97.value)}
                      </Text>
                    )}
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatCurrency(fields.contractPriceGas97.value)}
                    </Text>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatCurrency(fields.sdumGas97.value)}
                    </Text>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatPesoWithDecimalRange(fields.freightGas97.value, 4)}
                    </Text>
                  </>
                )}
                {addMode || editMode ? (
                  <>
                    <InputLitre
                      {...fields.estimatedGas97Volume}
                      onChange={modifyField}
                      className={styles.gasAmount}
                      disabled={loading}
                    />
                    <InputLitre
                      {...fields.existingGas97Volume}
                      // value={formatNumber(fields.existingGas97Volume.value, 3)}
                      onChange={modifyField}
                      className={styles.gasAmount}
                      disabled={loading}
                    />
                  </>
                ) : (
                  <>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatVolume(fields.estimatedGas97Volume.value)}
                    </Text>
                    <Text className={classNames(styles.fieldText, styles.gasAmount)}>
                      {formatVolume(fields.existingGas97Volume.value)}
                    </Text>
                  </>
                )}
              </div>
            </Field>
          </>
        )}

        {addMode && (
          <Button
            primary
            className={styles.continue}
            loading={loading}
            disabled={!isFormSubmittable || validateDiscount()}
            onClick={() => {
              submitForm(handleSubmit);
            }}
          >
            {locale.continue}
          </Button>
        )}
      </div>
    </>
  );
};
export default AccountDetailsFormModule;
